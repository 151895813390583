import React from 'react';
import './App.css';
import Dashboard from  './components/Dashboard/Dashboard.js';


function App() {
  return (
    <div>
       
      <Dashboard/>
    </div>
      
  );
}

export default App;
