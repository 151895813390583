import React, {Component } from 'react';
import './WeekView.css';
import firebase from '../../fire';
import Video from '../Video/Video.js';




class WeekView extends Component {
    constructor(props){
        super(props);
        this.state = {
            picks:null,
            pick_blocks: [],
            spread_blocks: [],
            total_blocks:[],
            spreads: null,
            totals: null,
            videoUrl:null,
            games: null,
            record: null,
      
           
            


        }
  
    }

    componentDidMount(){
      this.pullData(this.props.passcode)
    }
    componentWillMount(){
        this.pullData(this.props.passcode)
    }

    
   

   

  

    createBlocks(blocks, type){
       
        let boxes = [];
        if(blocks){
            for(let i = 0; i < blocks.length; i++){
                boxes.push(<div>
                    <p>{blocks[i]}</p>
                    
                </div>)
            }
        
            if(type === "pick"){
                this.setState({pick_blocks:boxes});
            }else if(type === "spread"){
                this.setState({spread_blocks:boxes});
            }else if(type === "total"){
                this.setState({total_blocks: boxes});
            }
        }

    }


  



    pullData(passcode){
        //console.log(this.props.week)
       let pickRef =  firebase.database().ref('users/'+passcode+'/'+this.props.week+'/picks/');
       pickRef.on('value', snapshot =>{
           if(snapshot.val()){
           this.setState({picks: snapshot.val()});
           this.createBlocks(this.state.picks, "pick");
           }
         }
       );  

       let spreadRef =  firebase.database().ref('users/'+passcode+'/'+this.props.week+'/spreads/');
       spreadRef.on('value', snapshot =>{
           if(snapshot.val()){
           this.setState({spreads: snapshot.val()})
           this.createBlocks(this.state.spreads, "spread");
           
           }
         }
       ); 


       let totalRef =  firebase.database().ref('users/'+passcode+'/'+this.props.week+'/totals/');
       totalRef.on('value', snapshot =>{
           if(snapshot.val()){
           this.setState({totals: snapshot.val()})
           this.createBlocks(this.state.totals, "total");
           
           }
         }
       ); 



       let videoUrlRef =  firebase.database().ref('users/'+passcode+'/'+this.props.week+'/youtubeUrl/');
       videoUrlRef.on('value', snapshot =>{
           if(snapshot.val()){
           this.setState({videoUrl: snapshot.val()})
           
           
           }
         }
       ); 


       let gamesRef =  firebase.database().ref('users/'+passcode+'/'+this.props.week+'/games/');
       gamesRef.on('value', snapshot =>{
           if(snapshot.val()){
           this.setState({games: snapshot.val()})
          // console.log(this.state.games)
        
           
           }
         }
       ); 
        

       if(this.state.picks && this.state.games){
            //this.createRecord(this.state.games, this.state.picks);
            this.setState({record: " Picks"})
       }


    }




    createRecord(games, picks){
        let winTotal = 0, lossTotal = 0, winPercent = 0;
        if(games){
            for(let i = 0; i < games.games.length; i++){
                for(let team in picks){
                    
                    if(games.games[i].home_team === picks[team]){
                        if(games.games[i].score.home > games.games[i].score.away){
                            winTotal++;
                        }else{
                            lossTotal++;
                        }
                        }else if(games.games[i].away_team === picks[team]){
                            if(games.games[i].score.away > games.games[i].score.home){
                                winTotal++;
                            }else{
                                lossTotal++;
                            }
                        }
                        
                    }
                }
                

            }

             winPercent =  Math.floor(((winTotal/Object.keys(picks).length)))*100;
                if(winPercent < 1){
                    this.setState({record: <p >Picks</p>})
                }else{
                    this.setState({record:<div>{winTotal}-{lossTotal} | { Math.floor(((winTotal/Object.keys(picks).length)*100))}%</div>})
                }

            //this.setState({record:<div>{winTotal}-{lossTotal} | { Math.floor(((winTotal/Object.keys(picks).length)*100))}%</div>})

        }

    


  



   
    

    displayPicks(){
        if(this.state.pick_blocks.length > 0 && this.state.pick_blocks){
                return(
                    <div className = {"WeekView_Picks"}>
                        <p className = {"Picks_Title"}>Top Picks</p>
                    {this.state.pick_blocks.map((item, index) => {
                    return(
                    
                    <li key={index}>
                        {item}
                    </li>
                    );
                    })}
                    </div>
                   
                )
            }
    }



    displaySpread(){
        if(this.state.spread_blocks.length > 0 && this.state.spread_blocks){
                return(
                    <div className = {"WeekView_Spread"}>
                        <p className = {"Picks_Title"}>Spread</p>
                    {this.state.spread_blocks.map((item, index) => {
                    return(
                    
                    <li key={index}>
                        {item}
                    </li>
                    );
                    })}
                    </div>
                   
                )
            }
    }


    displayTotal(){
        if(this.state.total_blocks.length > 0 && this.state.total_blocks){
                return(
                    <div className = {"WeekView_Total"}>
                        <p className = {"Picks_Title"}>Total</p>
                    {this.state.total_blocks.map((item, index) => {
                    return(
                    
                    <li key={index}>
                        {item}
                    </li>
                    );
                    })}
                    </div>
                   
                )
            }
    }


    showVideo(confirm){
        if(confirm){
            return (<div><Video url = {this.state.videoUrl|| null} width = {'420'} height = {'320'}/></div>)
        }else{
            return null;
        }
    }





    render(){

      
        return (
        
        <div className = {"WeekView_Container"}>
            <div className = {"WeekView_Header"}>
                <p className = {"Week_Header"}>{this.props.week}{this.state.record|| null}</p>
            </div>
            <div className = {"Spread_Layout"}>
            {this.displayPicks()}
            {this.displaySpread()}
            {this.displayTotal()}
            </div>
            
           {this.showVideo(this.props.video)}
            
           
        </div>)
    }


}





export default WeekView;