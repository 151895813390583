import React, {Component } from 'react';
import './Games.css';
import firebase from '../../../fire';
import  * as endpoints from '../../../assets/Endpoints';





class Games extends Component {
    constructor(props){
        super(props);
        this.state = {
            events: null,
            games: null,
        }
    }


    componentDidMount(){
        this.fetchData();
    }

    componentWillUnmount(){
        this.setState({ games:null, events: null});
        
    }

   

    



    fetchData(){
        fetch(endpoints.proxy_url+endpoints.nfl_game_data_url)
        .then(function(response) {
            if (!response.ok) {
                throw Error(response.statusText);
            }
            return response;
        })
        .then(result => {
        return result.json();
        }).then(data => {
            //data is the pulled request
            this.setState({events:data});
            this.getGames();
            
         
        
        }).catch(error => console.log(error));


      /*  fetch(endpoints.proxy_url+endpoints.bet_data_url)
        .then(function(response) {
            if (!response.ok) {
                throw Error(response.statusText);
            }
            return response;
        })
        .then(result => {
        return result.json();
        }).then(data => {
           //console.log(data)
           
            
        
        }).catch(error => console.log(error));*/



    }



    getGames(){
        let matchs = [], game;
        for(let key in this.state.events){
            game = {
                home_team: this.state.events[key].home.abbr,
                away_team: this.state.events[key].away.abbr,
                stadium: this.state.events[key].stadium,
                quarter: this.state.events[key].qtr,

                score: {
                    home: this.state.events[key].home.score.T || "TBD",
                    away: this.state.events[key].away.score.T ||"TBD"
                },

            }

            matchs.push(game);
        }

        this.setState({games:matchs})
        this.network(this.props.passcode_A, this.props.passcode_B, this.props.passcode_C);
      
       
    }



    createGameBoxes(){
        let boxes = [];
        for(let key in this.state.games){
            boxes.push(<div className = {"Game_Box"}>

                <h5>{this.state.games[key].home_team} vs. {this.state.games[key].away_team}</h5>
                <div className = {"Text"}> <p>{this.state.games[key].score.home}-{this.state.games[key].score.away}</p>
                <p>{this.state.games[key].quarter}</p>
                <p>{this.state.games[key].stadium}</p></div>
                




            </div>
            
            
            
            )
        }
        return boxes;
    }


    network(passcodeA, passcodeB, passcodeC){
        let database = firebase.database();
        database.ref('users/'+passcodeA+"/"+ this.props.week+"/games/").set({
            games: this.state.games, 
        });

        database.ref('users/'+passcodeB+"/"+ this.props.week+ "/games/").set({
            games: this.state.games, 
        });

        database.ref('users/'+passcodeC+"/"+ this.props.week+"/games/").set({
            games: this.state.games, 
        });


       
    }


    displayBoxes(){
        let gameBoxes = this.createGameBoxes();
        return (
            <div className = {"Scroller"}>
              {gameBoxes.map((item, index) => {
                return(
                
                <li key={index}>
                    {item}
                </li>
                );
                })}
            </div>
          );
    }





    render(){


        return (<div className = {"Stats_Container"}>
    <p className ={"Game_Title"}>{this.props.week|| "Games"}</p>
            {this.displayBoxes()}

            <div className = {'Stats_Inner_Container'}>
              <p>Follow Us on Twitter!</p> 
            </div>
           

           
        </div>)
    }


}




export default Games;