
import firebase from 'firebase/app';
import 'firebase/database'; 
import 'firebase/storage';  

const config = {
    apiKey: "AIzaSyDCvRoRGLLStrfPGJkF4OEdLwabUaS-PL4",
    authDomain: "betsportslikethepros.firebaseapp.com",
    databaseURL: "https://betsportslikethepros.firebaseio.com",
    projectId: "betsportslikethepros",
    storageBucket: "",
    messagingSenderId: "801347930250",
    appId: "1:801347930250:web:a43b0eacdfb62b7d634573"
  };


  let fire = firebase.initializeApp(config);
  export default fire;