import React, {Component } from 'react';
import './Video.css';
import YouTube from 'react-youtube';





class Video extends Component {
    constructor(props){
      super(props);
    }
      render() {
        const opts = {
          height: this.props.height,
          width: this.props.width,
          playerVars: { 
            autoplay: 1
          }
        };

        if(this.props.url){
    
        return (
          <div className = {"Video"}>
          <YouTube
            videoId= {this.getId(this.props.url)}
            opts={opts}
            onReady={this._onReady}
          />
          </div>
        );

        }else{
          return <div></div>;
        }
      }
     
      _onReady(event) {
        //there are other methods on the event.target. look into it.
        event.target.stopVideo();
      }
     


    getId(url){
      //Should probably do some regex at some point.
      if(url){
        if(url.includes("youtu.be") && url.includes("/")){
            return url.substring(16, url.length);
        } else if(url.includes("www.youtube.com")){
            console.log(url.substring(32, url.length))
            return url.substring(32, url.length)
        }else {
            return url;
        }

      }
        
    }

}
    
    
    export default Video; 