import React, {Component } from 'react';
import './CurrentEvent.css';
import  * as endpoints from '../../assets/Endpoints';



class CurrentEvent extends Component {
    constructor(props){
        super(props);
        this.state = {
            week: null,
        }
    }

    componentDidMount(){
       
    }

    displayWeek(){
        let date = new Date();
        let months = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
        if(this)
        return (<div><p>{months[date.getMonth()] +", "+date.getDate()}</p><p>{"NFL "+ (this.props.week || "Picks")}</p></div>);
        }
           
  
   




    render(){


        return (<div className = {"CurrentEvent_Container"}>
              
                 <div>{this.displayWeek()}</div>
                
              
        </div>)
    }


}

export default CurrentEvent;