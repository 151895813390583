import React, {Component } from 'react';
import './Dashboard.css';
import Banner from '../Banner/Banner.js';
import Panel from '../Panel/Panel.js';
import Phil from '../../assets/images/chip_phil.png';
import Mike from '../../assets/images/chip_mike.png';
import Cantu from '../../assets/images/chip_cantu.png';
import Games from '../Data/Games/Games';
import  * as endpoints from '../../assets/Endpoints';





class Dashboard extends Component {
    constructor(props){
        super(props);
        this.state = {
            show:false,
            games: null,
            week:null,
        }
        this.philCode = "3229033";
        this.brandonCode = "cantu2";
        this.mikeCode = "mikey4";
        this.toggleGames = this.toggleGames.bind(this);

    }

    componentDidMount(){
        this.getWeek();
    }

    getWeek =  async ()=> {
        const jay = await fetch(endpoints.proxy_url+endpoints.nfl_weekly_info)
        const son = await jay.json();
       this.setState({week:"Week " + JSON.stringify(son.w)}); 
    }

    toggleGames(e){
        
        (this.state.show)? this.setState({show:false}):this.setState({show:true});
        if(this.state.show){
            this.setState({games:<Games week = {this.state.week} passcode_A ={this.philCode} passcode_B ={this.brandonCode} passcode_C ={this.mikeCode} />});
        } else{
            this.setState({games:null})
        }
    }


    render(){


        return (
        <div className = {"Container"}>
            <button className={"Games_btn"} onClick = {this.toggleGames} type="submit">Games</button>
            <div className = {"Games"}>
            {this.state.games}
            </div>
            <div className = {"innerContainer"}>
           
                <div className = {"Banner_Container"}><Banner week = {this.state.week} philCode ={this.philCode} brandonCode = {this.brandonCode} mikeCode = {this.mikeCode} showVideo ={true} url = {"https://youtu.be/IwhD6h9NR_s"}/></div>
                
                <div className = {"Panels"}>
                
                    <Panel week = {this.state.week} title = {"Phil Hellmuth"} image = {Phil} passcode = {this.philCode} url = {"https://youtu.be/IwhD6h9NR_s"}/>
                    <Panel week = {this.state.week} title = {"Brandon Cantu"} image = {Cantu} passcode = {this.brandonCode}/>
                    <Panel week = {this.state.week} title = {"Mike 'The Mouth' Matusow"} image = {Mike} passcode = {this.mikeCode}/>
                </div>
               
            </div>
              
        </div>)
    }


}





export default Dashboard;