import React, {Component } from 'react';
import './Panel.css';
import firebase from '../../fire';
import Video from '../Video/Video.js';
import Tab from '../WeekView/Tab/Tab'
import  * as endpoints from '../../assets/Endpoints';



class Panel extends Component {
    constructor(props){
        super(props);
        this.state = {
            picks:null,
            pick_blocks: [],
            spread_blocks: [],
            total_blocks:[],
            spreads: null,
            totals: null,
            videoUrl:null,
            weeks:null,
            week_blocks: [],
            games:null,
           
            


        }
  
    }

    componentDidMount(){
        this.pullData(this.props.passcode);

       
    }

    

    createBlocks(blocks, type){
        let boxes = [];
        if(blocks){
            for(let i = 0; i < blocks.length; i++){
                boxes.push(<div>
                    <p>{blocks[i]}</p>
                    
                </div>)
            }
        
            if(type === "pick"){
                this.setState({pick_blocks:boxes});
            }else if(type === "spread"){
                this.setState({spread_blocks:boxes});
            }else if(type === "total"){
                this.setState({total_blocks: boxes});
            }
        }

    }


   



    pullData(passcode){
        
       let pickRef =  firebase.database().ref('users/'+passcode+'/'+this.props.week+'/picks/');
       pickRef.on('value', snapshot =>{
           if(snapshot.val()){
           this.setState({picks: snapshot.val()});
           this.createBlocks(this.state.picks, "pick");
           }
         }
       );  

       let spreadRef =  firebase.database().ref('users/'+passcode+'/'+this.props.week+'/spreads/');
       spreadRef.on('value', snapshot =>{
           if(snapshot.val()){
           this.setState({spreads: snapshot.val()})
           this.createBlocks(this.state.spreads, "spread");
           
           }
         }
       ); 


       let totalRef =  firebase.database().ref('users/'+passcode+'/'+this.props.week+'/totals/');
       totalRef.on('value', snapshot =>{
           if(snapshot.val()){
           this.setState({totals: snapshot.val()})
           this.createBlocks(this.state.totals, "total");
           
           }
         }
       ); 



       let videoUrlRef =  firebase.database().ref('users/'+passcode+'/'+this.props.week+'/youtubeUrl/');
       videoUrlRef.on('value', snapshot =>{
           if(snapshot.val()){
           this.setState({videoUrl: snapshot.val()})
           
           
           }
         }
       ); 


       let WeeksRef =  firebase.database().ref('users/'+passcode);
       WeeksRef.on('value', snapshot =>{
           if(snapshot.val()){
           this.setState({weeks: snapshot.val()})
           this.getWeeks()
           
           
           }
         }
       ); 


       let gamesRef =  firebase.database().ref('users/'+passcode+'/'+this.props.week+'/games/');
       gamesRef.on('value', snapshot =>{
           if(snapshot.val()){
               //console.log("snapSho", snapshot.val())
            this.setState({games: snapshot.val()})
           }
         }
       ); 



    }


    getWeeks(){
        let weeks = [];
        for(let key in this.state.weeks){
            weeks.push(key);
           
        }
        this.setState({week_blocks:weeks.reverse()}); //put back splice(1)
       // console.log(this.state.week_blocks)
    }


    displayWeekTabs(){
        if(this.state.week_blocks.length > 0 && this.state.week_blocks && this.props.week){
            return(
              
                <div className = {"Panel_Weeks"}>
                   
                {this.state.week_blocks.map((item, index) => {
                return(
                
                <Tab key={index} week = {item} passcode = {this.props.passcode}/>
                  
                
                );
                })}
                </div>
              
               
            )
        }
    }



  



   
    

    displayPicks(){
        if(this.state.pick_blocks.length > 0 && this.state.pick_blocks){
                return(
                    <div className = {"Panel_Picks"}>
                        <p className = {"Picks_Title"}>Top Picks</p>
                    {this.state.pick_blocks.map((item, index) => {
                    return(
                    
                    <li key={index}>
                        {item}
                    </li>
                    );
                    })}
                    </div>
                   
                )
            }
    }



    displaySpread(){
        if(this.state.spread_blocks.length > 0 && this.state.spread_blocks){
                return(
                    <div className = {"Panel_Spread"}>
                        <p className = {"Picks_Title"}>Spread</p>
                    {this.state.spread_blocks.map((item, index) => {
                    return(
                    
                    <li key={index}>
                        {item}
                    </li>
                    );
                    })}
                    </div>
                   
                )
            }
    }


    displayTotal(){
        if(this.state.total_blocks.length > 0 && this.state.total_blocks){
                return(
                    <div className = {"Panel_Total"}>
                        <p className = {"Picks_Title"}>Total</p>
                    {this.state.total_blocks.map((item, index) => {
                    return(
                    
                    <li key={index}>
                        {item}
                    </li>
                    );
                    })}
                    </div>
                   
                )
            }
    }





    render(){


        return (<div className = {"Panel_Container"}>
           
            <div className = {"Panel_Banner"}>
              
                <img className = {"Profile_Pictures"} src = {this.props.image} alt ={"not loaded"}/> 
                <p className = {"Name_Tag"}>{this.props.title}</p>
            </div>
            
            <div className = {"Panel_Current_Week_Container"}>
            <div className = {"Spread_Layout"}>
            {this.displayPicks()}
            {this.displaySpread()}
            {this.displayTotal()}
            </div>
           
            
           
          
            <Video url = {this.state.videoUrl|| null} width = {'420'} height = {'320'}/>
            </div>
            {this.displayWeekTabs()}
       
           
            
           
        </div>)
    }


}





export default Panel;