import React, {Component } from 'react';
import './SocialMedia.css';
import Web_Icon from '../../../assets/images/web_icon.png';
import Youtube_Icon from '../../../assets/images/youtube_icon.png';
import Twitter_Icon from '../../../assets/images/twitter_icon.png';





class Display extends Component {
    constructor(props){
        super(props);
        this.state = {

        }
    }

    render(){


        return (<div className = {"SocialMedia_Container"}>
            <p>Connect</p>
           <div className = {"Icon_Group"}> 
                <a href = {"https://twitter.com/phil_hellmuth"}><img style = {{width:"50px", margin:"10px"}}  src = {Twitter_Icon} alt ={"not loaded"}/></a>
                <a href = {"https://www.youtube.com/user/philhellmuthofficial/"}><img style = {{width:"50px", margin:"10px"}} src = {Youtube_Icon} alt ={"not loaded"}/></a>
                <a href = {"https://philhellmuth.com//"}> <img style = {{width:"50px", margin:"10px"}} src = {Web_Icon} alt ={"not loaded"}/></a>
            </div> 

           

           
        </div>)
    }


}





export default Display;