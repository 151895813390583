import React, {Component } from 'react';
import './Banner.css';
import SocialMedia from '../Data/SocialMedia/SocialMedia.js';
import Viewer from '../Viewer/Viewer.js';
import CurrentEvent from '../CurrentEvent/CurrentEvent';
import Selection from '../Data/Selection/Selection';
import Ticker from '../Data/Ticker/Ticker';
import WeekView from '../WeekView/WeekView';




class Banner extends Component {
    constructor(props){
        super(props);
        this.state = {

        }
    }





    render(){


        return (
        <div className = {"Banner_Container"}>
               <Selection week = {this.props.week}  philCode = {this.props.philCode}  brandonCode ={this.props.brandonCode} mikeCode = {this.props.mikeCode} />
                <div className = {"Middle_Container"}>
                    <div className = {'Title'}>
                       <p>Bet Sports</p>
                        <i className= {"Small_Text"}>{'Like the Pros!'}</i> 
                    </div>
                <div className ={"Viewer_Container"} >
                    <Viewer video = {this.props.showVideo} videoURL = {this.props.url} /> 
                    <div className = {'Banner_SocialMedia'}>
                        <SocialMedia/> 
                    </div>
                </div>
                </div>
                <p className = {"Sub_Text"}>With special commentary from Phil Hellmuth!</p>
                <div className = {"Bottom_Container"}>
                <CurrentEvent week = {this.props.week}/>
                {<div className = {'Banner_Data'}>
                    
                </div>}
                </div>
                <div className = {"Banner_Inner_Container"}>   
                    <div className = {"Ticker_Banner"}>
                      {/*  <Ticker/>*/}
                    </div>
                </div>

               
        </div>)
    }


}





export default Banner;