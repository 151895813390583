import React from 'react';
import './Ticker.css';
import  * as endpoints from '../../../assets/Endpoints';






var requestInterval = function (fn, delay) {
    var requestAnimFrame = (function () {
      return window.requestAnimationFrame || function (callback, element) {
        window.setTimeout(callback,  delay);
      };
    })(),
        start = new Date().getTime(),
        handle = {};
    function loop() {
      handle.value = requestAnimFrame(loop);
      var current = new Date().getTime(),
          delta = current - start;
      if (delta >= delay) {
        fn.call();
        start = new Date().getTime();
      }
    }
    handle.value = requestAnimFrame(loop);
    return handle;  
  };


  function randint(min, max) {
    return Math.floor(Math.random() * (max - min) + min);
}


function distanceBetween(a,b){
    return Math.abs(Math.floor(Math.sqrt(((b.x-a.x)*(b.x-a.x)) - ((b.y-a.y)*(b.y-a.y)))))
}



class Ticker extends React.Component{
    constructor(props){
        super(props);
        this.state ={
            bet_data:null,
            raw_data:null,
            data: null,

        }
        this.ctx = null;
        this.canvas = null;
        this.canvasRef  = React.createRef();
        this.frameRate = 1/10;
        this.frameDelay = this.frameRate*1000;
        this.WIDTH = window.innerWidth;;
        this.HEIGHT = 20;
        this.x = 0
        this.y = 10;
        this.teams =[];
        
        
        
    }



    fetchData(){

        
        fetch(endpoints.proxy_url+endpoints.bet_data_url)
        .then(function(response) {
            if (!response.ok) {
                throw Error(response.statusText);
            }
            return response;
        })
        .then(result => {
        return result.json();
        }).then(data => {
           
           this.setState({bet_data: data[0]});
           this.getBets();
          
          
           
            
        
        }).catch(error => console.log(error));

    }


    getBets(){
        let matches = [], game, events = this.state.bet_data.events, dump = [];;
         //console.log(events)
         for(let key in events){
             game = {
              description: events[key].description,
              gameline: events[key].displayGroups[0],
              home_total: events[key].displayGroups[0].markets[1].outcomes[0].price.handicap,
              away_total: events[key].displayGroups[0].markets[1].outcomes[1].price.handicap,
              home_moneyline:events[key].displayGroups[0].markets[0].outcomes[0].price.american,
              away_moneyline: events[key].displayGroups[0].markets[0].outcomes[1].price.american,
              home_spread: events[key].displayGroups[0].markets[0].outcomes[0].price.handicap,
              away_spread: events[key].displayGroups[0].markets[0].outcomes[1].price.handicap
 
             
            
             }
          // console.log(game)
             matches.push(game);
               //home_spread: events[key].displayGroups[0].markets[8].outcomes[1].price.handicap,
              //away_spread: events[key].displayGroups[0].markets[8].outcomes[0].price.handicap,
            
         }
 
         this.setState({raw_data: matches});  
         for(let key in this.state.raw_data){
            if( this.state.raw_data[key].home_spread && this.state.raw_data[key].away_spread ){
                dump.push(this.state.raw_data[key].description+ ": " + this.state.raw_data[key].home_spread + "|" + this.state.raw_data[key].away_spread)
            }else{
                dump.push(this.state.raw_data[key].description+ ": " + this.state.raw_data[key].home_total + "|" + this.state.raw_data[key].away_total)
            }

        this.setState({data: dump});
        this.teams = this.selectTeams(3);
        
      // console.log(randint(0,this.state.data.length-1))

        }
    }




    selectTeams(amount){
        let teams = [], selected;
        for(let i = 0; i < amount; i++){
            selected = this.state.data[randint(0,this.state.data.length-1)];
            if (this.teams.includes(selected)){
                teams.push(new Ticker_Text("Update Live!", i*500, this.y))  
            }else{
                teams.push(new Ticker_Text(selected, i*500, this.y));   
            }
        }
       
        return teams;

    }


    addText(string, x, y, size, color){
        this.ctx.font = size+"px Courier New";
        this.ctx.fillStyle = color;
        return this.ctx.fillText(string, x, y);
      }

    displayGames(){
        if(this.state.data){
            for(let i = 0; i < this.teams.length; i++){
                this.addText(this.teams[i].text, this.teams[i].x, this.teams[i].y, 12, "yellow");
                if(this.teams[i].x > this.WIDTH-this.teams[i].text.length){
                    this.teams[i].x = -this.teams[i].text.length;
                    this.teams[i].text = this.selectTeams(3)[randint(0,this.teams.length-1)].text;
                    
                    
                }
                else{
                    this.teams[i].x += 5;
                }
            }  
        }
    }

    checkDistance(){
        if(this.state.data){
        for(let i = 0; i <this.teams.length- 1; i++){
           if(distanceBetween(this.teams[i], this.teams[i+1]) <175){
               //console.log("hit")
                this.teams[i].x -= 600;
           }
            
        }
    }
    }

    update(){
        if(this.ctx){
            this.ctx.clearRect(0,0,this.WIDTH, this.HEIGHT);
            this.displayGames();
            this.checkDistance();

        }
    }

    componentDidMount(){
      this.ctx = this.canvasRef.current.getContext('2d');   
      this.fetchData();
      //console.log("hit Canvas Mount")
      requestInterval(() => {this.update()}, this.frameDelay );
    }


    render(){
        return(
            <div>
                <canvas id = 'canvas' 
                ref = {this.canvasRef} 
                className = {"Canvas"} 
                width = {window.innerWidth}
                height = {this.HEIGHT}
                />
            </div>
        )
    }


    



  }



class Ticker_Text{
    constructor(text, x, y){
        this.x = x;
        this.y = y;
        this.text = text;
    }

    draw(){
        //addText(this.text, this.x, this.y, 12, "yellow");
    }
}



  export default Ticker;