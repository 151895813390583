

export const nfl_game_data_url = "https://www.nfl.com/liveupdate/scores/scores.json";
export const bet_data_url = "https://www.bovada.lv/services/sports/event/v2/events/A/description/football/nfl";
export const proxy_url ="https://cors-anywhere.herokuapp.com/";
export const nfl_weekly_info = "https://www.nfl.com/liveupdate/scorestrip/ss.json";
export const header = new Headers({
    'Access-Control-Allow-Origin':"*",
    'Content-Type': 'text/plain',
    
    'Access-Control-Allow-Methods':'PUT, DELETE, GET',
});




