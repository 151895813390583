import React, {Component } from 'react';
import './Viewer.css';
import testImageA from '../../assets/images/nfl.png';   
import testImageB from '../../assets/images/contest_banner.jpg';
import testImageC from '../../assets/images/recap.jpg';
import testImageD from '../../assets/images/phil_book.png';
import testImageE from '../../assets/images/nfl.png';
import Video from '../Video/Video';


class Viewer extends Component {
    constructor(props){
        super(props);
        this.state = {
        
        }



    }





    shouldDisplayVideo(confirm, videoURL = null){
        if(!confirm){
            return(<div className = {"viewer_container"}>
            <div className = {"slider"}>
                <div className = {"mask"}></div>
                <ul>
                    <li className = {"firstanimation"}>
                        <img className = {"image"} src = {testImageA} alt ={"not loaded"} />
                        <div className = {"tooltip"}>
                            <h1>NFL Draft Picks!</h1>
                        </div>
                    </li>
                    <li className = {"secondanimation"}>
                        <img className = {"image"} src = {testImageB} alt ={"not loaded"} />
                        <div className = {"tooltip"}>
                            <h1>Join the Pros!</h1>
                        </div>
                    </li>

                    <li className = {"thirdanimation"}>
                        <img className = {"image"} src = {testImageC} alt ={"not loaded"} />
                        <div className = {"tooltip"}>
                            <h1>Check Last week Recap</h1>
                        </div>
                    </li>

                    <li className = {"fourthanimation"}>
                        <img className = {"image"} src = {testImageD} alt ={"not loaded"} />
                        <div className = {"tooltip"}>
                            <h1>New Book</h1>
                        </div>
                    </li>

                     <li className = {"fifthanimation"}>
                        <img className = {"image"} src = {testImageE} alt ={"not loaded"} />
                        <div className = {"tooltip"}>
                            <h1>Weekly Updates</h1>
                        </div>
                    </li>


                    <div className = {"progress_bar"}></div>




                </ul>
            </div>

        </div>)
        }else{
            return (<div className = {"viewer_container"}><Video url = {videoURL|| null} width = {'480'} height = {'320'}/></div>)
        }
    }

    


    render(){
        return (
       <div>{this.shouldDisplayVideo(this.props.video, this.props.videoURL )}</div>)
    }

}





export default Viewer;