import React, {Component } from 'react';
import './Selection.css';
import firebase from '../../../fire';
import  * as endpoints from '../../../assets/Endpoints';
import  * as utility from '../../../Utility.js';



class Selection extends Component {
    constructor(props){
        super(props);
        this.state = {
            events: null,
            games: null,
            choices:null,
            picks: null,
            isVaild: false,
            showInput: false,
            passcodeText: '',
            makePickShow:true,
            bet_data:null,
            spread:null,
            spreadInfo:null,
          
            picks: [],
            team_spreads:null,
            team_moneyLines:null,
            team_totals:null,
            urlText: '',
            
           
        }
        this.passcodes = {
            phil: this.props.philCode,
            cantu: this.props.brandonCode,
            mike: this.props.mikeCode
        }
        this.picks = [];
        this.handleOptionChange = this.handleOptionChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.makePickshandler = this.makePickshandler.bind(this);
        this.inputOnChange = this.inputOnChange.bind(this);
        this.submitPasscode = this.submitPasscode.bind(this);
        this.resetPicks = this.resetPicks.bind(this);
        this.youtubeChangeHandler = this.youtubeChangeHandler.bind(this);

        
    }




    componentDidMount(){
        this.fetchData(); 
    
    }

    submitPasscode(e){
        e.preventDefault();
        
        for(let key in this.passcodes){
            if(this.passcodes[key] === this.state.passcodeText){
                this.setState({isValid: true, showInput:false, makePickShow:false});
                
            }
        }

    }

    youtubeChangeHandler(e){
        this.setState({urlText: e.target.value});
        
    }

    inputOnChange(e){
        this.setState({passcodeText: e.target.value});
    }

    makePickshandler(e){
       
        this.setState({showInput: true, makePickShow:false});

    }


    handleSubmit(e){
        e.preventDefault();
       
        if(this.state.passcodeText.length > 2){
            this.network(this.state.passcodeText);
            this.setState({passcodeText: ''});
        }
        
        this.setState({showInput: false, makePickShow:true, isValid:false});

        
    }

   fetchData(){
        fetch(endpoints.proxy_url+endpoints.nfl_game_data_url)
        .then(function(response) {
            if (!response.ok) {
                throw Error(response.statusText);
            }
            return response;
        })
        .then(result => {
        return result.json();
        }).then(data => {
         
            this.setState({events: data});
            this.getGames();
            this.createRadios();
    
        
        }).catch(error => console.log(error));


       
       /* fetch(endpoints.proxy_url+endpoints.bet_data_url)
        .then(function(response) {
            if (!response.ok) {
                throw Error(response.statusText);
            }
            return response;
        })
        .then(result => {
        return result.json();
        }).then((data) => {
            
           //this.setState({bet_data: data[0].events})
           this.getBets();
           this.createSpread();
          
           
            
        
        }).catch(error => console.log(error));

       

        
      */  

     let get_bet =  async ()=> {
        const jay = await fetch(endpoints.proxy_url+endpoints.bet_data_url)
        const son = await jay.json();
       this.setState({bet_data:JSON.parse(JSON.stringify(son[0].events))});
       this.getBets();
       this.createSpread();
    }

  


    get_bet();
   
    }


    getGames(){
        let matchs = [], game;
        for(let key in this.state.events){
            game = {
                home_team: this.state.events[key].home.abbr,
                away_team: this.state.events[key].away.abbr,
                stadium: this.state.events[key].stadium,
                score: {
                    home: this.state.events[key].home.score.T,
                    away: this.state.events[key].away.score.T
                },

            }
            

            matchs.push(game);
        }

        this.setState({games:matchs})
       
    }

    handleOptionChange = changeEvent => {
        if(!this.state.picks.includes(changeEvent.target.value)){
            this.setState({picks:[...this.state.picks, changeEvent.target.value]})
        }
       
      };



    createRadios(){
        let boxes = [];
        for(let key in this.state.games){
            boxes.push( <div className="Radio">
            <label style = {{color:'red',}}>
              <input
                type="radio"
                name="react-tips"
                value= {this.state.games[key].home_team }
                checked={this.state.picks.includes(this.state.games[key].home_team) }
                onChange={this.handleOptionChange}
                className="form-check-input"
              />
                {this.state.games[key].home_team}
            </label>
            {"  vs.  "}
            <label style = {{color:"yellow"}}>
              <input
                type="radio"
                name="react-tips"
                value= {this.state.games[key].away_team}
                checked={ this.state.picks.includes(this.state.games[key].away_team)}
                onChange={this.handleOptionChange}
                className="form-check-input"
              />
              {this.state.games[key].away_team}
            </label>



          </div>
            
            
            )
        }
        this.setState({choices:boxes});
       
        return boxes;
    }

    resetPicks(e){
        e.preventDefault();
       
        this.setState({picks: []}); 
        this.network(this.state.passcodeText);

    }


    displayRadios(){
        if(this.state.choices){
            return (
                <div>
                <form onSubmit = {this.handleSubmit}>
                <div className = {"Matches"}>
                {this.state.choices.map((item, index) => {
                    return(
                    
                    <li key={index}>
                        {item}
                    </li>
                    );
                    })}
                </div>
                <div className="form-group">
                <input 
                    placeholder = {"youtube Url"}
                    onChange = {this.youtubeChangeHandler}
                    value = {this.state.urlText}


                />
                    <button className={"Button_Small"} type="submit">
                        Save
                    </button>
                </div>
                </form>

                <button className={"Button_Small"} onClick = {this.resetPicks} type="submit">
                    Reset Picks
                </button>

            
    </div>

          );

            }else{
                return <p>Choices not Available.Might be the connection to the server.</p>
            }
    }


    displayInput(){
        return ((this.state.showInput)?
            
            <form onSubmit = {this.submitPasscode}>
        <input
          type="text"
          name="password"
          value={this.state.passcodeText}
          onChange={this.inputOnChange}
          placeholder = {"passcode"}
        />
          <div className="form-group">
                <button className={"Button_Small"} type="submit">
                    submit
                </button>
            </div>
        </form>
            
            
            :null
            )
    }


    displayPicks(){
        if(this.state.isValid){
            if(this.state.picks){
        return(
            <div className = {"Picks"}>
            <p>Current Picks</p>
        {this.state.picks.map((item, index) => {
            return(
            
            <li key={index}>
                {item}
            </li>
            );
            })}
            </div>
        )
    }else{
        return <p>Picks not Available.Might be the connection to the server,</p>
    }

    }
}

    


getBets(){
    let matches = [], game, events = this.state.bet_data;
     //console.log(events)
     for(let key in events){
        
         game = {
          description: events[key].description,
          gameline: events[key].displayGroups[0],
          points: events[key].displayGroups[0].markets[1],
          moneyline:events[key].displayGroups[0].markets[0],   //.outcomes[0].price.american, //.outcomes[0].price.american
          total:events[key].displayGroups[0].markets[2],
         
        
         }
         
         matches.push(JSON.parse(JSON.stringify(game)));
          
        
     }

     this.setState({spread: matches});
     this.grabSpread();
     //console.log("spreads", this.state.spreads)
     this.mapTeamToSpread();
 }


    grabSpread(){
        let nums = [], point_spread = [];
    for(let key in this.state.spread){
        if(this.state.spread[key].points)
            nums.push(this.state.spread[key].points)
    }
     
      for(let key in nums){
          point_spread.push(nums[key].outcomes[0].price.handicap)
      }
      return point_spread
      
     /*  return({
           home:this.state.spread.filter(data => data.points)[0].points.outcomes[0].price.handicap,
           away: this.state.spread.filter(data => data.spread)[0].spread.outcomes[0].price.handicap      
    })*/

}


grabMoneyLineHome(){
    let nums = [], data = [];
for(let key in this.state.spread){
    if(this.state.spread[key].moneyline)
        nums.push(this.state.spread[key].moneyline)
}

  for(let key in nums){
      if(nums[key].outcomes[0])
        data.push(nums[key].outcomes[0].price.american)
  }
 
  return data
}

grabMoneyLineAway(){
    let nums = [], data = [];
for(let key in this.state.spread){
    if(this.state.spread[key].moneyline);
        nums.push(this.state.spread[key].moneyline);
}

  for(let key in nums){
      if(nums[key].outcomes[1])
        data.push(nums[key].outcomes[1].price.american);
  }
  
  return data
}

grabTotal(){
    let nums = [], data = [];
for(let key in this.state.spread){
    if(this.state.spread[key].total);
        nums.push(this.state.spread[key].total);
}

  for(let key in nums){
      if(nums[key])
        data.push(nums[key].outcomes[0].price.handicap);
  }
  //console.log(data)
  return data
}


    mapTeamToSpread(){
        let team_a, team_b, team_switch = { "LOSC":"LAC","LOSR":"LAR", "GRE":"GB", "JAC":"JAC","KAN":"KC", "NEWE":"NE","NEWO":"NO", "NEWG":"NYG", "NEWJ":"NYJ", "SAND":"SD", "SANF":"SF", "SAI":"STL", "TAM":"TB",  } ;
        let teams_spread = {},teams_moneyline = {}, teams_total = {};
        
        for(let key in this.state.spread){
            team_a = this.state.spread[key].description.substring(0,3).toUpperCase();
            let findTeamB = (description) =>{
                for(let i = 0; i < description.length; i++){
                    if(description[i] === "@"){
                        team_b = description.substring(i+2,i+5).toUpperCase();
                       
                        if (team_b === "NEW" || team_b === "SAN" || team_b === "LOS"){
                            if(description[i+11]=== "J"){
                                team_b = "NEWJ";
                            }else if(description[i+11] === "G"){
                                team_b ="NEWG";
                            } else if(description[i+14] === "R"){
                                team_b = "LOSR"
                            }else if(description[i+14] === "C"){
                                team_b = "LOSC"
                            }else{
                                team_b = description.substring(i+1,i+7 ).toUpperCase();
                                team_b = team_b.substring(0,4)+ team_b[team_b.length-1];       
                            }

                           

                        }
                        
                        
                        
                    }
                }

             
            }
            
            findTeamB(this.state.spread[key].description);
           
            if (team_a === "NEW" || team_a === "SAN" || team_a === "LOS"){
                if(this.state.spread[key].description[9] === "G"){
                    team_a = "NEWG";
                }else if(this.state.spread[key].description[9] === "J"){
                    team_a = "NEWJ";
                }else if(this.state.spread[key].description[12] === "R"){
                    team_a = "LOSR";
                }else if(this.state.spread[key].description[12] === "C"){
                    team_a = "LOSC";
                   
                }else{
                    team_a = this.state.spread[key].description.substring(0,5).toUpperCase();
                    team_a = team_a.substring(0,3)+ team_a[team_a.length-1];
                }

               

                
            } 
            //console.log("PRE", team_a, team_b)
           //filter and convert to right abreviation
           for(let abrr in team_switch){
                
               if(team_a === abrr){
                   team_a = team_switch[abrr];
                   
               }
               if(team_b === abrr){
                   team_b = team_switch[abrr];
               }
           }
            let point_spread = this.grabSpread()[key];
       
          teams_spread[team_a] = point_spread|| "TBD";
          teams_spread[team_b] = (parseFloat(point_spread) < 1)? point_spread: "-"+point_spread || "TBD";
          teams_moneyline[team_a] = this.grabMoneyLineHome()[key]|| "TBD";
          teams_moneyline[team_b] = this.grabMoneyLineAway()[key]|| "TBD";
          teams_total[team_a] = this.grabTotal()[key]|| "TBD";
          teams_total[team_b] = this.grabTotal()[key] || "TBD";
          
          
        }
        this.setState({team_spreads: teams_spread, team_moneyLines: teams_moneyline, team_totals:teams_total})

       //console.log(this.state.team_moneyLines)
    }


    createSpread(){
        let boxes = [];
        if(this.state.spread){
            for(let key in this.state.spread){
                //console.log(this.state.spread[key])
                boxes.push( (
                    <div>
                    <p>{this.state.spread[key].description}</p>
                    <p>{"Spread: "+ this.grabSpread()[key]|| "Not Available"}</p>
                    </div>
                ));
            }
            this.setState({spreadInfo: boxes});
        }else{
            return <p>Spread not Available.Might be the connection to the server,</p>
        }
    }


    displaySpread(){
        if(this.state.isValid){

            if(this.state.spreadInfo){
          
        return(
            <div className = {"Spread"}>
                {this.props.week}
        {this.state.spreadInfo.map((item, index) => {
            return(
            <li key={index}>
                {item}
            </li>
            )
            })}
            </div>
           
           
        )
    } else{
        return <p>Spread Info not Available.Might be the connection to the server,</p>
    }

    }

}


    network(passcode){
        let database = firebase.database(), spreads_dump = [], moneyline_dump = [], total_dump = [];
        if(this.state.picks){
        for(let i = 0; i < this.state.picks.length; i ++){
            //console.log(this.state.team_spreads[this.state.picks[i]])
            spreads_dump.push(this.state.team_spreads[this.state.picks[i]] || "TBD");
            moneyline_dump.push(this.state.team_moneyLines[this.state.picks[i]]|| "TBD");
            total_dump.push(this.state.team_totals[this.state.picks[i]] || "TBD")

        }
    }
        
        database.ref('users/'+passcode+"/"+ this.props.week).set({
            picks: this.state.picks,
            spreads: spreads_dump,
            moneylines: moneyline_dump,
            totals: total_dump,
            youtubeUrl: this.state.urlText,
            games: this.state.games,
        
        });


       
    }










   




    render(){


        return (
        <div className = {"Selection_Container"}>
           
           {( this.state.makePickShow) ? <button className ={"Button_Large"} onClick = {this.makePickshandler} ></button>: null}
            {(this.state.choices && this.state.isValid)?this.displayRadios():null}
            {this.displayInput()}
            {this.displayPicks()}
            {this.displaySpread()}
         
          
            {/*<div className = {"Selection_Inner_Container"}>
                <p>Selection Inner</p>
            </div>*/}
        </div>)
    }


}





export default Selection;