import React, { Component } from 'react';

import './Tab.css';
import WeekView from '../WeekView';


class Tab extends Component {
  constructor(props){
      super(props);
      this.state = {
        show: false,
      }
      this.handlePopButton = this.handlePopButton.bind(this);
  }


handlePopButton(e){
  e.preventDefault();
  this.setState({show: (this.state.show)? false: true})
 

}


popup(){
  if(this.state.show){
    return (
    <div>
    <WeekView week = {this.props.week} passcode = {this.props.passcode} show = {this.state.show} video = {true}/>
    <button className = {"Tab_Button_Close"} onClick = {this.handlePopButton}>Close {this.props.week}</button>
    </div>
    
    )
  }else{
    return (<div><button className = {"Tab_Button_Open"} onClick = {this.handlePopButton}>{this.props.week}</button></div>)
  }
}

render(){
  return (<div className = {"Tab_Container"}>
    {this.popup()}




  </div>)
}



}

export default Tab;